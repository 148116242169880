import React from 'react'
import './style.scss'
const FormDesign = () => {
  return (
    <>
      <div className="bgForm">
<div className="orange-circle"></div>
<div className="light-blue-circle"></div>
      </div>
    </>
  )
}

export default FormDesign
