import React from 'react'

const OnDevelopment = () => {
  return (
    <div className='text-center py-5'>
      <h1>
      This page still in Development
        </h1> 
    </div>
  )
}

export default OnDevelopment